.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.GreyBackground {
  background-color: #f0f0f0;
}

.TextAlignLeft {
  text-align: left;
}

.row {
  padding-left: 20px;
  padding-right: 20px;
}

.ServicesList {
  text-align: left;
  padding: 20px 0;
}

.alert {
  top: 10px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  min-width: 70%;
}

.MediaLogo {
  width: 40px;
  margin: 0 20px;
}

.MaterialIcon {
  position: relative;
  top: 6px;
  margin: 0 5px;
}

a {
  color: #335599;
  text-decoration: none;
  transition: color 0.3s;
}

a:hover {
  color: #5577ff;
  text-decoration: underline;
}

.SectionBGImage {
  object-fit: cover;
  height: 480px;
  width: 100%;
  opacity: 0.3;
}

.SectionWithBGImage {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: black;
}
